<!-- 疑问解答过程统计 -->
<template>
  <div style="background-color: #fff;padding: 24rem 0 0 24rem">
    <div class="flex">
      <el-date-picker v-model="form.time"
                      type="datetimerange"
                      value-format="yyyy-MM-dd HH:mm"
                      format="yyyy-MM-dd HH:mm"
                      :popper-append-to-body="true"
                      start-placeholder="筛选问题创建时间"
                      end-placeholder=""
                      size="small">
      </el-date-picker>
      <el-select v-model="form.school_id" placeholder="筛选校区" size="small" class="ml-16" @change="getClass">
        <el-option
            v-for="item in schoolOptions"
            :key="item.id"
            :label="item.school_name"
            :value="item.id">
        </el-option>
      </el-select>
      <el-select v-model="form.grade_id" placeholder="筛选年级" size="small" class="ml-16" @change="getClass">
        <el-option
            v-for="item in gradeOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id">
        </el-option>
      </el-select>
      <el-select v-model="form.teaching_id" placeholder="筛选班级" size="small" class="ml-16">
        <el-option
            v-for="item in classOptions"
            :key="item.teaching_id"
            :label="item.class_name"
            :value="item.teaching_id">
        </el-option>
      </el-select>
      <div class="ml-16">
        <el-button debounce :loading="searchLoading" size="small" type="primary" @click.prevent="onSearch">搜索</el-button>
        <el-button debounce :loading='resetLoading' size="small" style="margin-left: 16rem" @click.prevent="reset">重置</el-button>
      </div>
    </div>
    <div class="main" v-if="dataCont">
      <div class="main-box" v-for="(item, index) in mainConfig">
        <div class="top" @click="$router.push('../../../edu/process-record/answer/list?status=' + item.status)">
          <span class="span1">{{ item.name }}</span>
          <span>{{ item.text1 }}{{ dataCont[item.status].count }}条</span>
        </div>
        <div v-if="dataCont[item.status].list.length" class="bottom">
          <div class="card" v-for="_item in dataCont[item.status].list"
               @click="$router.push('../../../edu/process-record/answer/details?status=' + _item.status + '&id=' + _item.id)">
            <div class="flex">
              <div class="div1 text-one">
                <span class="span2">学生</span>
                <el-tooltip class="item" effect="dark" content="张三张三张三张三" placement="top-start">
                  <span class="c3">{{ _item.student_name }}</span>
                </el-tooltip>
              </div>
              <div class="div1 text-one">
                <span class="span2">教师</span>
                <el-tooltip class="item" effect="dark" content="李四李四李四李四" placement="top-start">
                  <span class="c3">{{ _item.teacher_name }}</span>
                </el-tooltip>
              </div>
            </div>
            <div class="text-one" style="margin: 10rem 0">
              <span class="span2">班级</span>
              <el-tooltip class="item" effect="dark" content="啦啦啦啦啦" placement="top-start">
                <span class="c3">{{ _item.teaching_name }}</span>
              </el-tooltip>
            </div>
            <div class="text-one">
              <span class="span2">{{ item.text2 }}</span>
              <template v-if="index === 2">
                <span v-if="_item.solve_status == 1">是</span>
                <span v-else class="c3">否</span>
              </template>
              <template v-else>
                <span v-if="_item.timeout == 1" style="color: red">是</span>
                <span v-else class="c3">否</span>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <t-result v-if="!isHaveData" ></t-result>
  </div>
</template>

<script>
export default {
	_config:{"route":{"path":"details","meta":{"title":"数据看板","keepAlive":true}}},
  data() {
    return {
      searchLoading: false,
      resetLoading: false,
      form: {
        time: '',
        school_id: '',
        grade_id: '',
        teaching_id: '',
      },
      schoolOptions: [],
      gradeOptions: [],
      classOptions: [],
      mainConfig: [
        {
          name: '待解答',
          status: 1,
          text1: '剩余',
          text2: '解答是否超时'
        },
        {
          name: '待反馈',
          status: 2,
          text1: '剩余',
          text2: '反馈是否超时'
        },
        {
          name: '已完成',
          status: 4,
          text1: '共计',
          text2: '是否解决问题'
        },
        {
          name: '已关闭',
          status: 5,
          text1: '共计',
          text2: '解答是否超时'
        }
      ],
      dataCont: '',
      isHaveData: true
    }
  },
  created() {
    this.onSearch()
    this.getConfig()
  },
  activated() {
    this.onSearch()
  },
  methods: {
    getConfig() {
      this.$_axios2('api/question/common/schools').then(res => {
        this.schoolOptions = res.data.data || []
      })
      this.$_axios2('api/question/common/grades').then(res => {
        this.gradeOptions = res.data.data || []
      })
    },

    getClass() {
      this.form.teaching_id = ''
      this.classOptions = []
      let params = {
        school_id: this.form.school_id,
        grade_id: this.form.grade_id
      };
      this.$_axios2('api/question/doubt/pc-teaching',{params}).then(res => {
        this.classOptions = res.data.data.teaching || []
      })
    },

    onSearch() {
      if(!this.resetLoading){
        this.searchLoading = true
      }
      let params = {
        school_id: this.form.school_id,
        grade_id: this.form.grade_id,
        teaching_id: this.form.teaching_id,
        start_time: this.form.time[0],
        end_time: this.form.time[1],
      }
      this.$_axios2('api/question/doubt/question-total', {params}).then(res => {
        this.dataCont = res.data.data || {}
        let boo = false;
        Object.keys(this.dataCont).forEach(item => {
          if(this.dataCont[item].count !== 0) boo = true;
        })
        this.isHaveData = boo
      }).finally(() => {
        this.searchLoading = false
        this.resetLoading = false
      })
    },

    reset() {
      this.resetLoading = true
      Object.keys(this.form).forEach(key => {
        this.form[key] = ''
      });
      this.onSearch()
    },
  },

}
</script>

<style scoped lang="scss">
::v-deep .el-date-editor--datetimerange.el-input__inner {
  width: 320rem !important;
}

::v-deep .el-select {
  width: 10.555vw !important;
  min-width: 152rem;;
}

.ml-16 {
  margin-left: 16rem;
}

.main {
  max-width: 2000rem;
  justify-content: space-between;
  display: flex;
  margin-top: 20rem;
  padding-right: 40rem;

  .main-box {
    width: 24%;

    .top {
      border-radius: 6rem;
      border: 1rem solid #ccc;
      height: 60rem;
      line-height: 60rem;
      text-align: center;
      cursor: pointer;

      span {
        font-size: 14rem;
      }

      .span1 {
        font-size: 18rem;
        margin-right: 38rem;
        font-weight: bold;
      }
    }

    .bottom {
      border-radius: 6rem;
      border: 1rem solid #ccc;
      padding: 16rem 16rem 0 16rem;
      margin-top: 20rem;

      .card {
        background: #f7f7f7;
        padding: 16rem;
        font-size: 14rem;
        color: rgba(0, 0, 0, .8);
        border-radius: 6rem;
        margin-bottom: 16rem;
        cursor: pointer;
        transition: .25s;

        &:hover{
          background: #eeeeee;
        }

        .span2 {
          color: rgba(0, 0, 0, .6);
          margin-right: 16rem;
        }

        .div1 {
          width: 50%;
          padding-right: 16rem;
        }
      }
    }
  }
}
</style>
